import {AbstractListItemAction} from 'sulu-admin-bundle/views';
import resourceRouteRegistry from 'sulu-admin-bundle/services/ResourceRequester/registries/resourceRouteRegistry';

export default class RejectSchoolProposalAction extends AbstractListItemAction {

    getItemActionConfig(item) {
        return {
            icon: 'su-times',
            disabled: false,
            onClick: item ? () => this.handleClick(item) : undefined,
        };
    }

    handleClick = (item) => {
        const url = resourceRouteRegistry.getDetailUrl(
            'schoolproposals',
            {id: item.id}
        ) + '/reject';

        const options = {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
        };

        fetch(url, options).then(this.listStore.reload());
    };
}
